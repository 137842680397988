export const scTranslation = {
  changeLang: "Change Language",
  explore: "探索",
  profile: "会员资料",
  debitCard: "金融卡",
  exclusiveEvents: "独家活动",
  trendingCoupons: "热门优惠券",
  all: "所有",
  coupon: "优惠券",
  detail: "详情",
  purchased: "已购买",
  expiration: "到期日",
  membershipBenifit: "会员优惠",
  myPoints: "我的积分",
  myCoupons: "我的优惠券",
  settings: "编辑个人",
  newMemberEvents: "新人活动",
  memberEvents: "会员活动",
  exchangePoints: "积分换兑",
  languages: "语言",
  cs: "官方客服",
  email: "电邮地址",
  phone: "电话号码",
  workingTimes: "工作时间",
  wokringTimeDetailOne: "星期一至周五（9：30-17：00）。",
  wokringTimeDetailTwo: "在星期六，周日和公共假期关闭。",
  ticket: {
    place: "适用地点",
    hk: "香港",
    howToUse: "如何使用",
    tnc: "条款与细则",
    get: "立即获取",
    use: "立即使用",
  },
  button: {
    returnToWallet: "返回钱包",
    continueExploring: "继续浏览",
    editProfile: "更新资料",
  },
  card: {
    tier: "会员阶级",
    graz: "恭喜",
    jade: "Jade",
    stat: "超过 99% 会员",
  },
  messages: {
    pleaseWait: "敬请期待",
    comingSoon: "快将推出。",
  },
  form: {
    name: "昵称",
    email: "电邮地址",
    dob: "出生日期",
    submit: "提交",
    save: "保存资料",
  },
};
