import React, { useState } from "react";
import { openNotificationWithIcon } from "src/components";
import { useCampaignStore } from "src/stores";
import { GroupSelection } from "./group-selection";

const ICON_CLASS = "w-[10px] h-[10px] ml-2";

const GROUP_A = ["qatar", "ecuador", "senegal", "netherlands"];
const GROUP_B = ["england", "iran", "usa", "wales"];
const GROUP_C = ["argentina", "saudi-arabia", "mexico", "poland"];
const GROUP_D = ["france", "australia", "denmark", "tunisia"];
const GROUP_E = ["spain", "costa-rica", "germany", "japan"];
const GROUP_F = ["belgium", "canada", "morocco", "croatia"];
const GROUP_G = ["brazil", "serbia", "switzerland", "cameroon"];
const GROUP_H = ["portugal", "ghana", "uruguay", "korea-republic"];

export const GroupStageGame = (props: any) => {
  const { setStage } = props;
  const [groupA, setGroupA] = useState([]);
  const [groupB, setGroupB] = useState([]);
  const [groupC, setGroupC] = useState([]);
  const [groupD, setGroupD] = useState([]);
  const [groupE, setGroupE] = useState([]);
  const [groupF, setGroupF] = useState([]);
  const [groupG, setGroupG] = useState([]);
  const [groupH, setGroupH] = useState([]);
  const { registry } = useCampaignStore();

  const isAllGroupsChecked = () => {
    return (
      groupA.length === 4 &&
      groupB.length === 4 &&
      groupC.length === 4 &&
      groupD.length === 4 &&
      groupE.length === 4 &&
      groupF.length === 4 &&
      groupG.length === 4 &&
      groupH.length === 4
    );
  };

  const submitAns = async (page: string) => {
    if (!isAllGroupsChecked()) {
      return openNotificationWithIcon(
        "warning",
        "One more step...",
        "Please confirm that you've submitted all your answers."
      );
    }

    const payload = {
      event: "wordcup_group_stage",
      data: [
        {
          group_a: groupA,
          group_b: groupB,
          group_c: groupC,
          group_d: groupD,
          group_e: groupE,
          group_f: groupF,
          group_g: groupG,
          group_h: groupH,
        },
      ],
    };

    const isRegistered = await registry(payload);
    if (!isRegistered.success) return;
    if (isRegistered.error === "jwt_error") return setStage("login");

    openNotificationWithIcon(
      "success",
      "Congratulations",
      "You've submitted your answers, stay tuned for our next round!"
    );

    setStage(page);
  };

  return (
    <div className="drop-shadow-2xl bg-white w-full min-h-[5px] p-5 rounded-2xl mt-5 max-w-[500px] mx-auto">
      <h5 className="text-at-blue leading-5">
        Predict the outcome of each group and rank them from 1st to 4th!
      </h5>

      <div className="pt-5">
        <div className="flex flex-row items-center">
          <p>Group A</p>
          {groupA.length === 4 && (
            <img
              src="/assets/check.png"
              className={ICON_CLASS}
              alt="check-icon"
            />
          )}
        </div>
        <GroupSelection data={GROUP_A} setAns={setGroupA} ans={groupA} />
      </div>

      {groupA.length === 4 && (
        <div className="pt-5">
          <div className="flex flex-row items-center">
            <p>Group B</p>
            {groupB.length === 4 && (
              <img
                src="/assets/check.png"
                className={ICON_CLASS}
                alt="check-icon"
              />
            )}
          </div>
          <GroupSelection data={GROUP_B} setAns={setGroupB} ans={groupB} />
        </div>
      )}

      {groupA.length === 4 && groupB.length === 4 && (
        <div className="pt-5">
          <div className="flex flex-row items-center">
            <p>Group C</p>
            {groupC.length === 4 && (
              <img
                src="/assets/check.png"
                className={ICON_CLASS}
                alt="check-icon"
              />
            )}
          </div>{" "}
          <GroupSelection data={GROUP_C} setAns={setGroupC} ans={groupC} />
        </div>
      )}

      {groupA.length === 4 && groupB.length === 4 && groupC.length === 4 && (
        <div className="pt-5">
          <div className="flex flex-row items-center">
            <p>Group D</p>
            {groupD.length === 4 && (
              <img
                src="/assets/check.png"
                className={ICON_CLASS}
                alt="check-icon"
              />
            )}
          </div>{" "}
          <GroupSelection data={GROUP_D} setAns={setGroupD} ans={groupD} />
        </div>
      )}

      {groupA.length === 4 &&
        groupB.length === 4 &&
        groupC.length === 4 &&
        groupD.length === 4 && (
          <div className="pt-5">
            <div className="flex flex-row items-center">
              <p>Group E</p>
              {groupE.length === 4 && (
                <img
                  src="/assets/check.png"
                  className={ICON_CLASS}
                  alt="check-icon"
                />
              )}
            </div>{" "}
            <GroupSelection data={GROUP_E} setAns={setGroupE} ans={groupE} />
          </div>
        )}

      {groupA.length === 4 &&
        groupB.length === 4 &&
        groupC.length === 4 &&
        groupD.length === 4 &&
        groupE.length === 4 && (
          <div className="pt-5">
            <div className="flex flex-row items-center">
              <p>Group F</p>
              {groupF.length === 4 && (
                <img
                  src="/assets/check.png"
                  className={ICON_CLASS}
                  alt="check-icon"
                />
              )}
            </div>{" "}
            <GroupSelection data={GROUP_F} setAns={setGroupF} ans={groupF} />
          </div>
        )}

      {groupA.length === 4 &&
        groupB.length === 4 &&
        groupC.length === 4 &&
        groupD.length === 4 &&
        groupE.length === 4 &&
        groupF.length === 4 && (
          <div className="pt-5">
            <div className="flex flex-row items-center">
              <p>Group G</p>
              {groupG.length === 4 && (
                <img
                  src="/assets/check.png"
                  className={ICON_CLASS}
                  alt="check-icon"
                />
              )}
            </div>{" "}
            <GroupSelection data={GROUP_G} setAns={setGroupG} ans={groupG} />
          </div>
        )}

      {groupA.length === 4 &&
        groupB.length === 4 &&
        groupC.length === 4 &&
        groupD.length === 4 &&
        groupE.length === 4 &&
        groupF.length === 4 &&
        groupG.length === 4 && (
          <div className="pt-5">
            <div className="flex flex-row items-center">
              <p>Group H</p>
              {groupH.length === 4 && (
                <img
                  src="/assets/check.png"
                  className={ICON_CLASS}
                  alt="check-icon"
                />
              )}
            </div>{" "}
            <GroupSelection data={GROUP_H} setAns={setGroupH} ans={groupH} />
          </div>
        )}

      {!isAllGroupsChecked() && (
        <p className="pt-5 rainbow">Rank 1 to 4 for each group!</p>
      )}

      <button
        className="primary mt-5 full py-2"
        onClick={() => {
          submitAns("preview");
        }}
      >
        <p className="text-white">Submit Prediction</p>
      </button>
    </div>
  );
};
