import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { openNotificationWithIcon } from "src/components";
import { t } from "i18next";

export class AuthStore {
  @action
  async getOtpCode(phoneNum: string, countryCode: string) {
    const url = `${Configs.apiUrl}/auth/get-otp`;
    try {
      const result: any = await axios.post(url, { phoneNum, countryCode });
      if (!result || !result["data"]) return generalError;
      return result["data"];
    } catch (e) {
      return generalError;
    }
  }

  @action
  async access(payload: any) {
    const url = `${Configs.apiUrl}/auth/access`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result["data"]) return generalError;
      if (!result["data"]["success"]) return generalError;

      const jwt = result["data"]["data"]["jwt"];
      localStorage.setItem("token", jwt);
      return result["data"];
    } catch (e) {
      let error: any = e;
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const message = error.response.data.message;
        openNotificationWithIcon("warning", "One more step...", t(message));
      }
      return generalError;
    }
  }
}

export const STORE_AUTH = "authStore";
