import React from "react";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const TITLE = "Earn AT points @ Fifa World Cup x AsiaTop";
const URL = "https://worldcup.asiatop.net";
const DESC = `Join us and play to earn in Fifa World Cup x AsiaTop. We have a perfect prediction price, and direct AT points rewarding to you all joining this campaign!`;
export const Preview = (props: any) => {
  const { setStage } = props;
  return (
    <div className="drop-shadow-2xl bg-white w-full min-h-[5px] p-5 rounded-2xl mt-5 max-w-[500px] mx-auto">
      <h5 className="text-at-blue leading-5">
        Thank you for joining our campaign!
      </h5>

      <div className="pt-5">
        <h5 className="text-at-pink">What is next?</h5>
        <p>
          The next event will be starting on December 2nd. Come back and play to
          earn!
        </p>
      </div>

      <div className="pt-5">
        <h5 className="text-at-pink">Join our AsiaTop 2.0</h5>
        <p>
          AT 2.0 is coming soon! Releasing late Oct to early Nov, the app will
          have many more events that allow users to{" "}
          <span className="rainbow">earn</span> more AT Rewards!
        </p>
        <ul className="mt-2 list-disc pl-5">
          <li className="mt-1 text-at-blue">
            Brand new membership system to enjoy merchants' benefits
          </li>
          <li className="mt-1 text-at-blue">Loyalty Points exchange</li>
          <li className="mt-1 text-at-blue">
            AT Birds, an NFT to upgrade your membership
          </li>
          <li className="mt-1 text-at-blue">
            Variety of coupons more than 5,000+ merchants
          </li>
          <li className="mt-1 text-at-blue">
            Unique Buy-Now-Pay-Later service
          </li>
          <li className="mt-1 text-at-blue">
            AT Card, spend anytime and anywhere
          </li>
        </ul>
      </div>

      <div className="pt-5">
        <h5 className="text-at-pink">Earn more by sharing!</h5>
        <div className="flex flex-row pt-1">
          {/* Whatsapp */}
          <WhatsappShareButton
            title={TITLE}
            url={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/whatsapp.png"
                className="w-8 h-8 object-fit mr-3"
                alt="whatsapp-share"
              />
            }
          />
          {/* Telegram */}
          <TelegramShareButton
            title={TITLE}
            url={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/telegram.png"
                className="w-[30px] h-[30px] object-fit mr-3"
                alt="telegram-share"
              />
            }
          />
          {/* Twitter */}
          <TwitterShareButton
            title={TITLE}
            url={URL}
            hashtags={[
              "AsiaTop",
              "Loyalty",
              "web3",
              "FifaWorldCup",
              "Fifa",
              "WorldCup",
              "Soccer",
              "NFT",
              "blockchain",
            ]}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/twitter.png"
                className="w-[30px] h-[30px] object-fit mr-3"
                alt="twitter-share"
              />
            }
          />
          {/* Line */}
          <LineShareButton
            title={TITLE}
            url={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/line.png"
                className="w-8 h-8 object-fit mr-3"
                alt="line-share"
              />
            }
          />
          {/* Linkedin */}
          <LinkedinShareButton
            title={TITLE}
            summary={`Join us and play to earn in Fifa World Cup x AsiaTop. We have a perfect prediction price, and direct AT points rewarding to you all joining this campaign!`}
            url={URL}
            source={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/linkedin.png"
                className="w-[30px] h-[30px] object-fit mr-3"
                alt="linkedin-share"
              />
            }
          />
          {/* Facebook */}
          <FacebookShareButton
            title={TITLE}
            url={URL}
            quote={DESC}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/facebook.png"
                className="w-8 h-8 object-fit mr-3"
                alt="facebook-share"
              />
            }
          />
        </div>
      </div>

      <div className="pt-5">
        <h5 className="text-at-pink">See you soon again!</h5>
      </div>

      <button
        className="primary mt-5 full py-2"
        onClick={() => {
          setStage("login");
        }}
      >
        <p className="text-white">Back to Home</p>
      </button>
    </div>
  );
};
