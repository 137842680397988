import React, { useState } from "react";
import { openNotificationWithIcon } from "src/components";
import { useAuthStore } from "src/stores";

const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-at-blue focus:ring-at-blue block w-full rounded-md sm:text-sm focus:ring-1";

export const LoginForm = (props: any) => {
  const { setStage } = props;
  const [countryCode, setCountryCode] = useState("852");
  const [phoneNum, setPhoneNum] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { getOtpCode, access } = useAuthStore();

  const handleInput = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "country") return setCountryCode(value);
    if (type === "phoneNum") return setPhoneNum(value);
    if (type === "otp") return setOtp(value);
  };

  const getCode = async () => {
    if (loading) return;
    setLoading(true);
    if (countryCode === "" || phoneNum === "") {
      return openNotificationWithIcon(
        "warning",
        "One more step...",
        "Please enter your country code and phone number."
      );
    }

    const isOtpCodeSent = await getOtpCode(phoneNum, countryCode);
    setLoading(false);
    if (!isOtpCodeSent.success) {
      return openNotificationWithIcon(
        "warning",
        "One more step...",
        "Failed to send OTP code, please check your country code and phone number."
      );
    }

    return openNotificationWithIcon(
      "success",
      "Congratulations",
      "We have sent the OTP code to your phone number. It will available for 5 minutes."
    );
  };

  const verifyCode = async () => {
    if (countryCode === "" || phoneNum === "" || otp === "") {
      return openNotificationWithIcon(
        "warning",
        "One more step...",
        "Please enter your country code, phone number and verification code."
      );
    }

    const isLoggedIn = await access({ phoneNum, countryCode, otp });
    if (!isLoggedIn.success) return;

    setStage("game");
    return openNotificationWithIcon(
      "success",
      "Congratulations",
      "Please feel free to play the game!"
    );
  };

  return (
    <div className="drop-shadow-2xl bg-white w-full min-h-[5px] p-5 rounded-2xl mt-5 max-w-[500px] mx-auto">
      <h5 className="text-at-blue leading-5">
        <span className="rainbow">Play</span> and predict the world cup results
        to <span className="rainbow">earn</span> AT points!
      </h5>

      <div className="pt-5">
        <p>Phone Number</p>
        <div className="flex flex-row relative">
          <input
            type="text"
            name="country"
            className={`${inputClass} w-20 mr-2 pl-6`}
            onChange={(e) => handleInput(e, "country")}
            value={countryCode}
          />
          <p className="text-slate-500 absolute bottom-[10px] left-[10px]">+</p>
          <input
            type="text"
            name="number"
            className={inputClass}
            onChange={(e) => handleInput(e, "phoneNum")}
            value={phoneNum}
          />
        </div>
      </div>

      <div className="pt-5 relative">
        <p>Verification Code</p>
        <input
          type="text"
          name="code"
          className={`${inputClass} pr-20`}
          onChange={(e) => handleInput(e, "otp")}
          value={otp}
        />
        <p
          className="text-at-pink absolute bottom-[9px] right-[10px] cursor-pointer"
          onClick={() => getCode()}
        >
          Get Code
        </p>
      </div>

      <button
        className="primary mt-5 full py-2"
        onClick={() => {
          verifyCode();
        }}
      >
        <p className="text-white">Start Group Stage</p>
      </button>

      <div className="pt-5">
        <h5 className="pb-2">Prediction Period</h5>
        <p>Group Stage - Now to 20 Nov</p>
        <p className="text-slate-500 mt-2">Round of 16 - 2 Dec to 3 December</p>
        <p className="text-slate-500 mt-2">
          Quarter Finals - 6 Dec to 8 December
        </p>
        <p className="text-slate-500 mt-2">
          Semi Finals - 10 Dec to 12 December
        </p>
        <p className="text-slate-500 mt-2">
          3rd Place & Finals - 14 Dec to 16 December
        </p>
      </div>

      <div className="pt-5">
        <h5 className="text-at-pink">How do we play? What’s the prize?</h5>
        <p>
          Simply make a prediction for which team will win each match. Every
          correct prediction will get you AT Rewards, directly rewarded to you
          in our app. Get them all correct and win our Perfect Prediction Prize!
        </p>
        <ul className="mt-2 list-disc pl-5">
          <li className="mt-1 text-at-blue">
            Perfect Prediction Price - <br></br>{" "}
            <span className="rainbow">10k AT Points + AT NFT</span>
            <br></br>
            <span>Limited 1 person, by earliest Submission</span>
          </li>
          <li className="mt-1 text-at-blue">
            1 AT Point per correct prediction<br></br>Directly into your account
            wallet!
          </li>
        </ul>
      </div>
    </div>
  );
};
