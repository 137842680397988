// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { AuthStore, STORE_AUTH } from "./auth";
import { STORE_CAMPAIGN, CampaignStore } from "./campaign";
import { STORE_ROUTER, RouterStore, history } from "./router";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_CAMPAIGN]: new CampaignStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useCampaignStore() {
  const { campaignStore } = useStores();
  return campaignStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
