import React, { useEffect, useState } from "react";
import { Container } from "../../components";
import { GroupStageGame } from "./group-stage-game";
import { LoginForm } from "./login-form";
import { Preview } from "./preview";

export const Home = (props: any) => {
  const [stage, setStage] = useState("login"); // login, game, preview

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [stage]);

  return (
    <Container>
      <div className="p-5 z-10 relative">
        <img
          src="/assets/trophy.png"
          className="w-20 h-20 mx-auto"
          alt="trophy"
        />
        <h1 className="text-white text-center pt-3 leading-[40px] text-3xl">
          Fifa World Cup <br></br> x <br></br> AsiaTop
        </h1>

        {stage === "login" && <LoginForm setStage={setStage} />}
        {stage === "game" && <GroupStageGame setStage={setStage} />}
        {stage === "preview" && <Preview setStage={setStage} />}
      </div>
    </Container>
  );
};
