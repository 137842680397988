import React from "react";

export const GroupSelection = (props: any) => {
  const { data, setAns, ans } = props;

  const handleClick = (item: string) => {
    if (ans.indexOf(item) > -1) {
      let newAns = ans;
      newAns = ans.filter((country: string) => item !== country);
      return setAns(newAns);
    }
    return setAns([...ans, item]);
  };

  return (
    <div className="flex flex-row justify-between pt-2">
      {data.map((item: any, i: any) => {
        return (
          <div
            className="flex flex-col items-center max-w-[40px] text-center"
            key={i}
            onClick={() => handleClick(item)}
          >
            <img
              src={`/assets/flags/${item}.png`}
              alt={`${item}-flag`}
              className="w-10 h-10 object-fit"
            />
            <p className="text-slate-600 text-[8px]">
              {item.replace("-", " ")}
            </p>

            {ans.indexOf(item) > -1 && (
              <p className="text-at-pink">{ans.indexOf(item) + 1}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};
